import React from "react";
import { Boat } from "../types";

interface BoatCardProps {
  boat: Boat;
  onClick: () => void;
}

export const BoatCard: React.FC<BoatCardProps> = ({ boat, onClick }) => {
  const operationalCameras = boat.cameras.filter(
    (camera) => camera.syncList[0]?.status === "OK"
  ).length;

  const failedCameras = boat.cameras.filter((camera) =>
    ["Indisponível", "Monocromático"].includes(camera.syncList[0]?.status)
  ).length;

  const hasFailure = failedCameras > 0;

  const lastUpdate = boat.cameras
    .flatMap((camera) => camera.syncList.map((sync) => new Date(sync.time)))
    .reduce((latest, current) => (current > latest ? current : latest), new Date(0));

  const now = new Date();
  const minutesSinceLastUpdate = Math.floor((now.getTime() - lastUpdate.getTime()) / 60000);

  return (
    <div
      onClick={onClick}
      style={{
        border: "1px solid #ddd",
        padding: "1rem",
        margin: "1rem",
        cursor: "pointer",
      }}
    >
      <h2>{boat.name}</h2>
      <p>Total cameras: {boat.cameras.length}</p>
      <p>Operacionais: {operationalCameras}</p>
      <p>Com falha: {failedCameras}</p>
      <p>Última atualização: {lastUpdate.toLocaleString()}</p>
      <p>Tempo desde a última atualização: {minutesSinceLastUpdate} minutos</p>
      <span style={{ color: hasFailure ? "red" : "green" }}>
        {hasFailure ? "Falha" : "OK"}
      </span>
    </div>
  );
};
