import React from "react";
import { Camera } from "../types";

interface CameraCardProps {
  camera: Camera;
  onClick: () => void;
}

export const CameraCard: React.FC<CameraCardProps> = ({ camera, onClick }) => {
  const latestSync = camera.syncList[0];
  const latestStatus = latestSync?.status || "OK";
  const lastUpdate = latestSync ? new Date(latestSync.time) : new Date();

  const now = new Date();
  const minutesSinceLastUpdate = Math.floor(
    (now.getTime() - lastUpdate.getTime()) / 60000
  );

  return (
    <div
      onClick={onClick}
      style={{
        border: "1px solid #ddd",
        padding: "1rem",
        margin: "1rem",
        cursor: "pointer",
      }}
    >
      <h3>{camera.name}</h3>
      <span style={{ color: latestStatus === "OK" ? "green" : "red" }}>
        {latestStatus}
      </span>
      <p>Última atualização: {lastUpdate.toLocaleString()}</p>
      <p>Tempo desde a última atualização: {minutesSinceLastUpdate} minutos</p>
    </div>
  );
};
