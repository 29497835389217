import React from "react";
import logo from "../assets/images/logo.webp";

export const Header: React.FC<{
  onLogoClick: () => void;
  onShowBoats: () => void;
  onShowFailedBoats: () => void;
  showFailedBoats: boolean;
  onLogout: () => void;
}> = ({
  onLogoClick,
  onShowBoats,
  onShowFailedBoats,
  showFailedBoats,
  onLogout,
}) => (
  <header
    style={{
      backgroundColor: "#164b73",
      color: "white",
      padding: "1rem",
      display: "flex",
      alignItems: "center",
    }}
  >
    <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
      <img
        src={logo}
        alt="Logo"
        style={{ cursor: "pointer", height: "100px" }}
        onClick={onLogoClick}
      />
      <h1 style={{ marginLeft: "1rem" }}>Camera Monitor</h1>
    </div>
    <nav style={{ flex: 2, display: "flex", justifyContent: "center" }}>
      <ul style={{ display: "flex", listStyle: "none", margin: 0, padding: 0 }}>
        <li style={{ marginRight: "1rem" }}>
          <button
            onClick={onShowBoats}
            style={{
              background: showFailedBoats ? "none" : "white",
              border: "1px solid white",
              color: showFailedBoats ? "white" : "#164b73",
              cursor: "pointer",
              fontSize: "1rem",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
            }}
          >
            Barcos
          </button>
        </li>
        <li>
          <button
            onClick={onShowFailedBoats}
            style={{
              background: showFailedBoats ? "white" : "none",
              border: "1px solid white",
              color: showFailedBoats ? "#164b73" : "white",
              cursor: "pointer",
              fontSize: "1rem",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
            }}
          >
            Alertas
          </button>
        </li>
      </ul>
    </nav>
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <button
        onClick={onLogout}
        style={{
          background: "none",
          border: "1px solid white",
          color: "white",
          cursor: "pointer",
          fontSize: "1rem",
          padding: "0.5rem 1rem",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          marginRight: "1rem",
        }}
      >
        Sair
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          style={{ width: "1rem", height: "1rem", marginLeft: "0.5rem" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1m0-14v1"
          />
        </svg>
      </button>
    </div>
  </header>
);
