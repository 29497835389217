import React, { useState } from "react";
import { SyncItem } from "../types";

interface SyncListProps {
  syncList: SyncItem[];
  cameraName: string;
  boatName: string;
}

export const SyncList: React.FC<SyncListProps> = ({
  syncList,
  cameraName,
  boatName,
}) => {
  const [sortField, setSortField] = useState<"status" | "time">("time");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const sortedSyncList = [...syncList].sort((a, b) => {
    const fieldA = sortField === "time" ? new Date(a.time).getTime() : a.status;
    const fieldB = sortField === "time" ? new Date(b.time).getTime() : b.status;

    if (fieldA < fieldB) return sortOrder === "asc" ? -1 : 1;
    if (fieldA > fieldB) return sortOrder === "asc" ? 1 : -1;
    return 0;
  });

  const handleSort = (field: "status" | "time") => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}
    >
      <div>
        <h2>
          Lista de sincronização {cameraName} - {boatName}
        </h2>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                onClick={() => handleSort("status")}
                style={{
                  cursor: "pointer",
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Status{" "}
                {sortField === "status" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th
                onClick={() => handleSort("time")}
                style={{
                  cursor: "pointer",
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Data {sortField === "time" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedSyncList.map((item, index) => (
              <tr key={index}>
                <td
                  style={{
                    color: item.status === "OK" ? "green" : "red",
                    padding: "8px",
                  }}
                >
                  {item.status}
                </td>
                <td style={{ padding: "8px" }}>
                  {new Date(item.time).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
