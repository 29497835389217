import { Boat } from "./types";

export const mockBoats: Boat[] = [
  {
    name: "Parcel dos Reis",
    cameras: [
      {
        name: "DVR01",
        syncList: [
          { status: "OK", time: "2024-08-12 12:34:56" },
          { status: "Indisponível", time: "2024-08-11 08:21:43" },
        ],
      },
      {
        name: "DVR02",
        syncList: [
          { status: "OK", time: "2024-08-13 14:45:22" },
          { status: "OK", time: "2024-08-12 09:15:33" },
        ],
      },
      {
        name: "CAMERA03",
        syncList: [
          { status: "OK", time: "2024-08-13 10:00:00" },
          { status: "OK", time: "2024-08-12 14:22:45" },
        ],
      },
      {
        name: "CAMERA04",
        syncList: [
          { status: "OK", time: "2024-08-12 11:11:11" },
          { status: "OK", time: "2024-08-11 17:45:23" },
        ],
      },
    ],
  },
  {
    name: "Parcel das Timbebas",
    cameras: [
      {
        name: "DVR01",
        syncList: [
          { status: "OK", time: "2024-08-12 10:00:00" },
          { status: "Monocromático", time: "2024-08-11 15:22:45" },
        ],
      },
      {
        name: "DVR02",
        syncList: [
          { status: "Indisponível", time: "2024-08-11 11:11:11" },
          { status: "OK", time: "2024-08-10 17:45:23" },
        ],
      },
      {
        name: "CAMERA03",
        syncList: [
          { status: "OK", time: "2024-08-13 08:00:00" },
          { status: "OK", time: "2024-08-12 12:30:45" },
        ],
      },
      {
        name: "CAMERA04",
        syncList: [
          { status: "Indisponível", time: "2024-08-12 09:22:22" },
          { status: "OK", time: "2024-08-11 14:10:15" },
        ],
      },
    ],
  },
  {
    name: "Austral Abrolhos",
    cameras: [
      {
        name: "CAMERA01",
        syncList: [
          { status: "Monocromático", time: "2024-08-13 13:14:15" },
          { status: "OK", time: "2024-08-12 16:47:50" },
        ],
      },
      {
        name: "DVR01",
        syncList: [
          { status: "Indisponível", time: "2024-08-12 19:33:21" },
          { status: "OK", time: "2024-08-11 22:00:00" },
        ],
      },
      {
        name: "CAMERA03",
        syncList: [
          { status: "OK", time: "2024-08-13 11:20:30" },
          { status: "OK", time: "2024-08-12 15:44:00" },
        ],
      },
      {
        name: "CAMERA04",
        syncList: [
          { status: "Indisponível", time: "2024-08-12 14:05:10" },
          { status: "OK", time: "2024-08-11 12:30:30" },
        ],
      },
    ],
  },
];
