import React, { useState } from "react";
import { Header } from "./components/Header";
import { BoatCard } from "./components/BoatCard";
import { CameraCard } from "./components/CameraCard";
import { SyncList } from "./components/SyncList";
import { Login } from "./components/Login"; // Importando o novo componente
import { mockBoats } from "./mockData";
import { Boat, Camera } from "./types";

const App: React.FC = () => {
  const [boats] = useState<Boat[]>(mockBoats);
  const [selectedBoat, setSelectedBoat] = useState<Boat | null>(null);
  const [selectedCamera, setSelectedCamera] = useState<Camera | null>(null);
  const [showFailedBoats, setShowFailedBoats] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const validCredentials = [
    { username: "Oceanpact", password: "Ocean306" },
    { username: "teste", password: "teste" },
  ];

  const handleLogin = (username: string, password: string) => {
    const isValid = validCredentials.some(
      (cred) => cred.username === username && cred.password === password
    );
    if (isValid) {
      setIsLoggedIn(true);
    } else {
      alert("Login ou senha inválidos!");
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const handleLogoClick = () => {
    setSelectedBoat(null);
    setSelectedCamera(null);
    setShowFailedBoats(false);
  };

  const handleShowBoats = () => {
    setSelectedBoat(null);
    setSelectedCamera(null);
    setShowFailedBoats(false);
  };

  const handleShowFailedBoats = () => {
    setSelectedBoat(null);
    setSelectedCamera(null);
    setShowFailedBoats(true);
  };

  const handleBackToBoats = () => {
    setSelectedBoat(null);
    setSelectedCamera(null);
  };

  const handleBackToCameras = () => {
    setSelectedCamera(null);
  };

  const failedBoats = boats.filter((boat) =>
    boat.cameras.some((camera) =>
      ["Indisponível", "Monocromático"].includes(camera.syncList[0]?.status)
    )
  );

  const Breadcrumb = () => (
    <nav
      aria-label="breadcrumb"
      style={{ marginBottom: "1rem", marginLeft: "1rem" }}
    >
      <ol style={{ display: "flex", listStyle: "none", padding: 0 }}>
        <li
          style={{
            cursor: "pointer",
            color: selectedBoat || selectedCamera ? "blue" : "black",
          }}
          onClick={handleBackToBoats}
        >
          Barcos
        </li>
        {selectedBoat && (
          <>
            <span style={{ margin: "0 0.5rem" }}>/</span>
            <li
              style={{
                cursor: "pointer",
                color: selectedCamera ? "blue" : "black",
              }}
              onClick={handleBackToCameras}
            >
              {selectedBoat.name}
            </li>
          </>
        )}
        {selectedCamera && (
          <>
            <span style={{ margin: "0 0.5rem" }}>/</span>
            <li style={{ color: "black" }}>{selectedCamera.name}</li>
          </>
        )}
      </ol>
    </nav>
  );

  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div>
      <Header
        onLogoClick={handleLogoClick}
        onShowBoats={handleShowBoats}
        onShowFailedBoats={handleShowFailedBoats}
        showFailedBoats={showFailedBoats}
        onLogout={handleLogout}
      />
      {!showFailedBoats && <Breadcrumb />}
      {showFailedBoats ? (
        <div>
          {failedBoats.map((boat) => (
            <div key={boat.name} style={{ marginBottom: "2rem" }}>
              <h2
                style={{
                  backgroundColor: "#f8d7da",
                  padding: "1rem",
                  borderRadius: "8px",
                }}
              >
                {boat.name}
              </h2>
              <ul style={{ listStyle: "none", paddingLeft: "1rem" }}>
                {boat.cameras
                  .filter((camera) =>
                    ["Indisponível", "Monocromático"].includes(
                      camera.syncList[0]?.status
                    )
                  )
                  .map((camera) => (
                    <li
                      key={camera.name}
                      style={{
                        marginBottom: "1rem",
                        padding: "1rem",
                        backgroundColor: "#f1f1f1",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                      }}
                    >
                      <strong>{camera.name}</strong>
                      <p style={{ margin: "0.5rem 0" }}>
                        Status:{" "}
                        <span
                          style={{
                            color:
                              camera.syncList[0]?.status === "OK"
                                ? "green"
                                : "red",
                          }}
                        >
                          {camera.syncList[0]?.status}
                        </span>
                      </p>
                      <p style={{ margin: "0.5rem 0" }}>
                        Última atualização:{" "}
                        {new Date(camera.syncList[0]?.time).toLocaleString()}
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
      ) : selectedBoat ? (
        selectedCamera ? (
          <div>
            <SyncList
              syncList={selectedCamera.syncList}
              cameraName={selectedCamera.name}
              boatName={selectedBoat.name}
            />
          </div>
        ) : (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {selectedBoat.cameras.map((camera) => (
              <CameraCard
                key={camera.name}
                camera={camera}
                onClick={() => setSelectedCamera(camera)}
              />
            ))}
          </div>
        )
      ) : (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {boats.map((boat) => (
            <BoatCard
              key={boat.name}
              boat={boat}
              onClick={() => setSelectedBoat(boat)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default App;
